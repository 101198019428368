exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aplicativos-js": () => import("./../../../src/pages/Aplicativos.js" /* webpackChunkName: "component---src-pages-aplicativos-js" */),
  "component---src-pages-aulas-js": () => import("./../../../src/pages/Aulas.js" /* webpackChunkName: "component---src-pages-aulas-js" */),
  "component---src-pages-elements-js": () => import("./../../../src/pages/Elements.js" /* webpackChunkName: "component---src-pages-elements-js" */),
  "component---src-pages-generic-js": () => import("./../../../src/pages/Generic.js" /* webpackChunkName: "component---src-pages-generic-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-livros-js": () => import("./../../../src/pages/Livros.js" /* webpackChunkName: "component---src-pages-livros-js" */),
  "component---src-pages-livrosk-js": () => import("./../../../src/pages/Livrosk.js" /* webpackChunkName: "component---src-pages-livrosk-js" */)
}

